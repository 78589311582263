/* GoToTopButton.css */
.go-to-top-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
  }
  
  .go-to-top-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
height: 50px;
width: 50px;
    background-color:white;
    color: var(--primary-text-color);
    border: solid 3px var(--primary-bg-color);

    border-radius: 50px;
    margin-bottom: 10px;
    transition: opacity 0.3s;
  }
  
  .go-to-top-button:hover {
    color: #fff;
    background-color: var(--primary-bg-color);
  }
  
  .progress-bar {
    width: 50px;
    height: 5px;
    background-color: #ccc;
    border: solid 1px white;
    border-radius: 3px;
    position: relative;
  }
  
  .progress {
    height: 100%;
    background-color: var(--primary-bg-color);
    border-radius: 3px;
    transition: width 0.3s;
  }

