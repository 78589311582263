:root {
  --white: white;
  --globlecolor: #f90;
  --gray: #666;
  --mainbcolor: #f4f5f8;
}
/* banner imagesection  */
.bimage {
  background-image: url("../images/BlogImages/bg-1.jpg");
  width: 100%;
  height: 80vh;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  flex-direction: column;
  background-color: var(--mainbcolor);
}

.bimage::before {
  background-color: #222;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  content: " ";
  z-index: -1;
}

.bclip {
  background-color: var(--globlecolor);
  color: var(--white);
  height: 25px;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 3%;
  clip-path: polygon(93% 0, 100% 30%, 100% 100%, 0 100%, 0 0);
}

.clipheading {
  font-size: 100%;
  letter-spacing: 1px;
  line-height: 120%;
  font-weight: 500;
  padding: 0px 15px;
}

.bhead {
  color: var(--white);
  font-size: 3rem;
}

.bpara {
  font-size: 1.2rem;
  color: var(--white);
}

.bsubhead {
  font-size: 1rem;
  color: var(--white);
}

.bicon {
  color: var(--globlecolor);
}
.secound-section {
  background-color: var(--mainbcolor);
  padding-top: 2%;
}

.image-section {
  width: 60%;
  height: 60vh;
}

.img-1 {
  width: 100%;
  height: 100%;
}

.para-section {
  width: 60%;
}

.para {
  margin-top: 5%;
  width: 100%;
  height: 100%;
  color: var(--gray);
  font-size: 120%;
}

.para-section1 {
  width: 60%;
}

.para1 {
  color: var(--gray);
  font-size: 120%;
  width: 100%;
  height: 100%;
}
.blockquote-section {
  width: 60%;
  background-color: var(--white);
  border-left: 4px solid var(--globlecolor);
}

.quote {
  padding: 4%;
  width: 100%;
  height: 100%;
}

.doubleqoute {
  color: var(--globlecolor);
  font-size: 200%;
}

.quotepara {
  font-size: 180%;
  padding-top: 2%;
  font-weight: 600;
}

.blockname {
  color: var(--gray);
  text-transform: uppercase;
  font-size: 80%;
  font-weight: 600;
}

.para-section2 {
  width: 60%;
  margin-top: 3%;
}

.para2 {
  color: var(--gray);
  font-size: 120%;
  width: 100%;
  height: 100%;
}

.image-section1 {
  width: 60%;
}

.img {
  padding: 0.5%;
}

.imge1 {
  width: 100%;
  height: 100%;
}
.para-section3 {
  width: 60%;
}

.para3 {
  font-weight: 600;
  font-size: 120%;
}

.para-section4 {
  width: 60%;
}

.para4 {
  width: 100%;
  height: 100%;
  color: var(--gray);
  font-size: 120%;
}

.para-section5 {
  width: 60%;
}

.para5 {
  width: 100%;
  height: 100%;
  color: var(--gray);
  font-size: 120%;
}

.button-section {
  width: 60%;
}

.grpbtn {
  background-color: var(--white);
  margin: 0.4%;
  border: 1px solid var(--white);
  border-radius: unset;
  transition: all 0.5s;
}

.grpbtn:hover {
  background-color: var(--globlecolor);
}
.author-section {
  width: 60%;
  height: 100;
  background-color: var(--white);
}

.author-info1 {
  width: 20%;
  position: relative;
}

.author {
  position: absolute;
  top: 25%;
  left: 20%;
}

.author-info2 {
  width: 80%;
  padding-right: 2%;
}

.author-name {
  font-weight: 600;
}

.author-para {
  font-size: 120%;
  font-weight: 400;
  color: var(--gray);
}

.media-icon {
  margin-top: 3%;
  margin-bottom: 5%;
}

.social-icon {
  cursor: pointer;
}

.post-section {
  width: 60%;
}

.post {
  font-size: 130%;
  font-weight: 600;
}
.post1 {
  font-weight: 600;
  border-bottom: 4px solid var(--globlecolor);
}

.comments-section {
  width: 60%;
}
.comments-section1 {
  width: 60%;
  margin-left: 10%;
}

.user-section {
  margin: 0% 2% 2% 2%;
}

.userimg {
  border-radius: 50%;
}

.username {
  font-weight: 500;
}

.commentdate {
  font-size: 80%;
  font-weight: 500;
  color: var(--gray);
}

.userpara {
  font-size: 110%;
  color: var(--gray);
  font-weight: 400;
  margin-top: 1%;
}

.replybtn {
  border: none;
  font-size: 70%;
  color: var(--gray);
  font-weight: 700;
  background-color: var(--mainbcolor);
}

/* form section  */
.write-comt-section {
  width: 60%;
}

.leave-comment {
  font-size: 130%;
  font-weight: 600;
}
.borderb {
  font-weight: 600;
  border-bottom: 4px solid var(--globlecolor);
}

.comment-form {
  width: 60%;
}

.form-input {
  border: none;
  border-radius: unset;
  width: 100%;
  height: 100%;
  font-weight: 600;
  padding-left: 2%;
}
.form-input1 {
  font-weight: 600;
  width: 100%;
  border: none;
  padding-left: 1%;
  border-radius: unset !important;
}
.form-input0 {
  font-weight: 600;
  padding-left: 2%;
  border: none;
  border-radius: unset;
  width: 100%;
  height: 100% !important;
}
::placeholder {
  color: var(--gray);
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 1.2rem;
}
.form-input:focus {
  color: var(--globlecolor);
  outline: 1px solid var(--globlecolor) !important;
}
.form-input0:focus {
  color: var(--globlecolor);
  outline: 1px solid var(--globlecolor) !important;
}
.form-input1:focus {
  color: var(--globlecolor);
  outline: 1px solid var(--globlecolor) !important;
}


@keyframes squeeze3124 {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@media (max-width: 500px) {
  .bclip {
    margin-top: 50%;
  }

  .bhead {
    font-size: 2rem;
    text-align: center;
  }

  .bpara {
    text-align: center;
  }

  .span-container {
    margin-bottom: 50% !important;
  }

  .bsubhead {
    display: block;
  }

  .image-section {
    display: block;
    width: 100% !important;
    height: 100% !important;
    margin: 0% 4%;
  }

  .para-section {
    display: block;
    width: 100% !important;
    margin: 5% 4% 0% 4% !important;
  }

  .para-section1 {
    width: 100% !important;
    margin: 0% 4% 0% 4% !important;
  }

  .blockquote-section {
    display: block;
    width: 100% !important;
    margin: 5% 4% 0% 4% !important;
  }

  .para-section2 {
    width: 100% !important;
    margin: 8% 4% 0% 4% !important;
  }

  .image-section1 {
    width: 100% !important;
    display: block !important;
    margin: 8% 4% 0% 4% !important;
  }

  .img {
    margin: 2% 0.6% 0% 0.6%;
  }

  .para-section3 {
    width: 100% !important;
    margin: 10% 4% 0% 4% !important;
  }

  .para-section4 {
    width: 100% !important;
    margin: 0% 4%;
  }

  .para-section5 {
    width: 100% !important;
    margin: 0% 4%;
  }

  .grpbtn {
    width: 60% !important;
    margin: 1% !important;
    padding: 1%;
  }

  .author-section {
    width: 100%;
    margin: 0% 4%;
    display: none;
  }
  .author-info1 {
    width: 100%;
    display: none;
  }
  .author-info2 {
    width: 100%;
    display: none;
  }
  .post-section {
    width: 100% !important;
    margin-left: 4%;
  }
  .comments-section {
    width: 100% !important;
    margin: 0% 4%;
  }
  .comments-section1 {
    width: 100% !important;
    margin: 0% 4%;
  }

  .user-section {
    width: 100% !important;
    height: 21% !important;
    margin: 3% !important;
  }

  .write-comt-section{
    width: 100%;
    margin: 0% 4%;
  }
  .comment-form{
    width: 100% !important;
    margin: 0% 4%;
  }
  .form-input {
    height: 100%;
    width: 100%;
    padding-top: 5% !important;
  }
  .form-input0 {
    height: 100%;
    width: 100%;
    margin-top: 5% !important;
  }
  button{
    margin-left: 10% !important;
  }
}

@media (min-width: 768px) {
  .author-info1 {
    margin-right: 8%;
  }
  .form-input0 {
    height: 140% !important;
    width: 100%;
  }
}
@media (max-width: 770px) {
  .author-info1 {
    margin-right: 15%;
  }
  button{
    margin-left: 5% !important;
  }
}
