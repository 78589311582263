:root {
    --mustard: #ff9900;
    --white: #ffffff;
    --gray: #ccc;
    --black: #000000;
}

.bcg {
    background-color: rgb(28, 28, 28);
    background-image: url('../images/Footer/texture.png');
    background-repeat: repeat;
    background-size: cover;

}

.first-col {
    width: 100%;
}

.logo-section {
    width: 60%;
    margin-left: 5%;
    margin-top: 90px !important;
}
.logo-section .mylogo {

    margin-left: -5%;
    margin-top: -15%;
}

.mylogo {
    width: 100%;
    height: 150%;
}

.para-section {
    border-left: 1px solid var(--white);
    font-weight: 400;
    color: var(--white);
    letter-spacing: 0.5px;
    border: none;
}

.secondcol {
    clip-path: polygon(100% 0, 100% 100%, 12% 100%, 0 5%, 0 0);
    background-color: var(--mustard);
    background-image: repeating-linear-gradient(45deg, #f7a20f 0, #f7a20f 2px, transparent 0, transparent 50%);
    background-size: 10px 10px;
    width: 70%;
    margin-right: -1%;
    margin-top: -0.4%;
}

.call div {
    font-size: 40px;
    color: var(--white);
    padding: 15px 20px;
    margin-left: -35%;
    margin-top: 2.2%;
}

.call span {
    color: var(--white);
    display: block;
    margin-top: 19%;
    font-size: 16px;
    margin-bottom: 2%;
}

.call p a {
    text-decoration: none;
    font-size: 28px;
    font-weight: bold;
    color: var(--black);
}

/* list items css  */
.middle-section {
    background-image: url('../images/Footer/city-skiline.png');
    background-size: 100%;
    background-position: bottom center;
    width: 100%;
    height: 100%;
    /* z-index: 1; */
    background-repeat: no-repeat;
    overflow: hidden;
}

.list {
    margin-bottom: 7%;
}

.footer-item {
    color: var(--white);
}

.title h1 {
    font-size: 20px;
    line-height: 1.7;
    position: relative;
    color: var(--white);
}

.title h1::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: -78%;
    right: 0;
    margin: auto;
    width: 20%;
    height: 4px;
    background-color: var(--mustard);
}

.footer-content {
    padding: 5px;
}

.footer-item li {
    list-style-type: none;
    margin: 20px 0px;
    font-size: 16px;
}

.footer-item li span {
    display: block;
    font-size: 14px !important;
    color: var(--mustard);
    margin: 2px 0px;
}

.last h5 {
    font-size: 17px;
    color: var(--gray);
}

.special li {
    margin: 10px 0px;
}


.cars {
    margin-top: -8%;
    position: relative;
}

.movingcar {
    width: 7%;
    animation: swing 12s linear 0s infinite forwards;
    position: absolute;
    bottom: 0 !important;
    right: 0;
}

.movingcar img {
    width: 100%;
}

@keyframes swing {
    0% {
        right: -30%;
    }

    100% {
        right: 100%;
    }
}

.movingcar2 {
    width: 7%;
    animation: swing2 15s linear 0s infinite forwards;
    position: absolute;
    bottom: 0;
    right: 0;
}

.movingcar2 img {
    width: 100%;
}

@keyframes swing2 {
    0% {
        right: -30%;
    }

    100% {
        right: 100%;
    }
}

.movingcar3 {
    width: 7%;
    animation: swing3 18s linear 0s infinite forwards;
    position: relative;
}

.movingcar3 img {
    width: 100%;
}

@keyframes swing3 {
    0% {
        right: 200px;
        top: 2px;
    }

    100% {
        right: -100%;
        top: 2px;
    }
}


.email-box {
    clip-path: polygon(90% 0, 100% 26%, 100% 100%, 0 100%, 0 0);
    padding: 4%;
    width: 100%;
}

::placeholder {
    letter-spacing: 1px;
    font-size: 17px;
}

.designbtn {
    clip-path: polygon(100% 0, 81% 100%, 0 100%, 0 0);
    width: 80%;
    /* max-height: 50vh; */
    padding: 4%;
    background-color: var(--mustard);
    letter-spacing: 1px;
    font-weight: bold;
    margin-top: -0.3%;
    color: var(--white) !important;
    text-align: left;
}

.designbtn:hover {
    background-color: var(--mustard);
    transition: all 0.4s;
}

.middle-spacebottom {
    margin-bottom: 9em;
}



@media (max-width: 460px) {
    .footer {
        flex-wrap: wrap;
    }

    .first-col {
        width: 100%;
    }

    .secondcol {
        width: 100%;
    }

    .call div {
        margin-left: 5%;
    }

    .secondcol {
        clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 5%, 0 0);
    }

    .logo-section {
        display: block;
        width: 100%;
        margin-top: 20% !important;
    }

    .logo-section span {
        font-size: 100%;
    }

    .logo-section img {
        width: 80%;
    }

    .para-section {
        display: block;
        width: 100%;
        border: none;
    }

    .email-box {
        width: 70%;
        padding: 1% 5%;
    }

    .designbtn {
        width: 50%;
        padding: 1%;
        text-align: center;
        letter-spacing: .2px;
        font-size: 80%;
        margin-bottom: 5%;
    }

    .middle-section {
        background-size: 180%;
    }

    .movingcar {
        width: 20%;
    }

    .movingcar2 {
        width: 20%;
    }

    .movingcar3 {
        width: 20%;
    }
}

@media (max-width:1024px) {
    .logo-section {
        width: 60%;
        margin-left: 15%;
        margin-top: 14% !important;
    }

    .mylogo {
        width: 150%;
        height: 100%;
        margin-left: -50% !important;
    }

    .para-section {
        border-left: 1px solid var(--white);
        padding-left: 5%;
        font-weight: 400;
        letter-spacing: 0.5px;
    }



    .call div {
        margin-left: -30%;
        margin-top: 2.2%;
    }

    .email-box {
        padding: 2%; 
    }
    .designbtn {
        padding: 2%;
    }
    .middle-spacebottom {
        margin-bottom: 10%;
    }
}

@media (max-width:768px) {
    .logo-section {
        width: 100%;
        margin-left: 13%;
        margin-top: 20% !important;
    }

    .mylogo {
        width: 140% !important;
        height: 50%;
        margin-left: -46% !important;
        margin-top: -3% !important;
    }

    .para-section {
        margin-top: 30px !important;
        ;
    }

    .call div {
        margin-left: -15%;
        margin-top: 2.2%;
    }
    .email-box {
        width: 90%;
        padding: 1%; 
    }
    .designbtn {
        padding: 1%;
    }
    .middle-spacebottom {
        margin-bottom: 15%;
    }

}

@media (max-width:425px) {
    .logo-section {
        width: 100%;
        margin-left: 7%;
    }

    .mylogo {
        width: 120% !important;
        height: 40%;
        margin-left: -20% !important;
    }
    .email-box {
        width: 60%;
        padding: 0%; 
        
    }
    .designbtn {
        width: 50%;
        padding: 0%;
        text-align: left;
    }
    .middle-spacebottom {
        margin-bottom: 20%;
    }
}

@media (max-width:390px) {
    .logo-section {
        width: 100%;
        margin-left: 10%;
    }

    .mylogo {
        width: 125% !important;
        height: 25%;
        margin-left: -20% !important;
    }
}

@media (max-width:320px) {
    .logo-section {
        width: 100%;
        margin-left: 7%;
    }

    .mylogo {
        width: 100% !important;
        height: 20%;
        margin-left: -10% !important;
    }

    .middle-spacebottom {
        margin-bottom: 20%;
    }
}
.footer-appzmine{
    text-decoration: none;
    color: white;
    font-weight: bold;
}