
.ordered{
    margin: 0% 10%;
    font-size: 1.2em; 
    text-align: start;
    padding: 0% !important; 
   
    color: #ffb914 !important;
}
.ordered2 {
    color: #ffbb1d !important;
    padding: 4% 0% !important;
    position: relative;
    font-weight: bold;
    letter-spacing: 1px;
    text-shadow: 2px 3px 4px #ffd10334, 2px 3px 4px #0000000e;
    font-size: 20px;
    
}
.ordered2::after{
    content: "";
    position: absolute;
    width: 0%;
    top: 0%;
    left: 0%;
    height: 100%;
    border-bottom: 3px solid black;
    transition: ease-in-out 0.4s !important;
    cursor: pointer;
}
.ordered2:hover.ordered2::after{
    width: 100%;
} 


.navbar {
    height: 80px;
    width: 100%;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    font-size: 1.2rem;
    z-index: 8888;
    position: sticky;
    top: 0;
    transition: opacity 0.1s ease-in-out; 
    opacity: 0; 
}
.navbar.show {
    opacity: 1;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.525); 
}

.nav-logo {
	text-decoration: none;
	color: rgb(0, 0, 0);
}

.nav-links {
	display: grid;
	grid-template-columns: repeat(10, auto);
	grid-gap: 70px;
	list-style: none;
}

.nav-link {
	text-decoration: none;
	color: black;
	transition: 0.3s all;
}

.nav-link:hover {
	color: #ffb914;
}

.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}

@media only screen and (max-width: 500px) {
	.navbar {
		position: relative;
	}
    .navbar.show {
        opacity: 1;
        width: 100%;
        transition: opacity 0.1s ease-in-out;
        position: fixed;
        top: 0; 
    }
    
    .nav-link{
        color: black;
    }
	.nav-links {
		display: flex;
		flex-direction: column;
        align-items: start;
		position: absolute;
		text-align: center;
		width: 100%;
		top: 94px;
		left: -100%;
		transition: ease-in-out 0.4s;
        padding: 2% 10%;
        grid-template-columns: repeat(4, auto);
	    grid-gap: 0px;
	}

	.nav-links.active {
		background: rgb(255, 255, 255);
		left: 0;
	}


	.nav-icon {
		display: flex;
	}
}

@media screen and (min-width:1440px){
   .navbar{
    margin-top: -5.6%;
    height: 4.7em;
   }
}
@media screen and (max-width:1024px){
   .navbar{
    margin-top: -8%;
    height: 5em;
   }
   .nav-links{
    grid-template-columns: repeat(8, auto);
	grid-gap: 40px;
   }
}
@media screen and (max-width:800px){
   .navbar{
    margin-top: 0%;
    height: 5em;
   }
   .nav-links{
    grid-template-columns: repeat(6, auto);
	grid-gap: 20px;
   }
}

@media only screen and (min-width:500px) and (max-width:768px){
    .Logo{
        display: none;
    }
}