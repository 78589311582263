.animax{
    font-family: inherit;
    font-size: 20px;
    background: rgb(31, 31, 31);
    color: rgb(0, 0, 0);
    padding: 0.3em 1em;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 2px;
    overflow: hidden;
    transition: ease-in-out 0.2s;
    cursor: pointer;
    box-shadow: 2.5px 4px 5px 0px #ffb301b4;
  }
  .animax:hover{
    box-shadow: 2.5px 4px 5px 0px #ff0101b4;
  }
  .imgshome{
    width: 2em;
  }
  .animax span{
    color: #ffffff;
  }
  
  .animax span {
    display: block;
    margin-left: 0.5em;
    transition: all 0.3s ease-in-out;
  }
  
  .animax img {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
  }

  .animax:active{
    box-shadow: none;
  }
  .animax:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }
  
  .animax:hover img {
    transform: translateX(2.8em) scale(1.1);
  }
  
  .animax:hover span {
    transform: translateX(6.2em);
  }
  
  .animax:active {
    transform: scale(0.95);
  }
  
  @keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }
  
    to {
      transform: translateY(-0.1em);
    }
  }

  .holds-image{
    width: 90%;
    height: 100%;
  }
  .para-graph{
    color: black !important;
    text-align: start;
  }

  @media screen and (max-width:768px)and (min-width:768px){
    .holds-image{
      width: 100%;
      height: 70%;
    }
  }
  