/* This is ABout Banner  */
@media only screen and (max-width: 425px) {

}

@media only screen and (min-width: 768px) {

}
@media only screen and (min-width: 1024px) {
  
}
.About-Main-Row {
    /* z-index: -2; */
    position: relative;
    background-image: url("../images/About/7-mini_1.jpeg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed; 
    min-height: 60vh;
    margin-top: 5.8rem;
}
.About-info{
  margin-top: -6%;
}


.About-Heading {
  margin-top: 120px;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 55px;
  color: #ffffff;
  text-shadow: 1px 2px 10px rgb(0, 0, 0);
  
}

.Phone-Call-Logo {
  width: 55px;

}

.Need-Help {
  margin-top: -87px;
  font-weight: bold;
  color: #FF9900;
}

.About-info {
  font-weight: bold;
  color: #FF9900;
  
}

.HeadingABT {
  font-weight: bold;
  font-size: 39px;
}

.Numb {
  font-weight: bold;
}

.Business {
  font-size: 40px;
  font-weight: bold;
}

.Rent-car {
  font-size: 39px;
  font-weight: bold;
  color: #FF9900;
}
.about-promo-image{
  margin-left: 15% ;
}



@media screen and (max-width:600px) {
  .containimg {
     display: none;
  }
  .About-info{
    margin-top: -25%;
  }
}

@media screen and (max-width:768px) and (min-width:768px){
  .About-info{
    margin-top: -11.5%;
  }
}

