  
.man{
    height: 90%;
    width: 35%;
    position: absolute;
    right:15%;
    top: 10%;
}
.maindiv{
    background-color: rgb(29, 27, 27);
    clip-path: polygon(100% 0, 100% 50%, 74% 100%, 0 100%, 0 0);
    height: 480px;
    position: relative;
    
}
.ride{
    color: white;
}
.newtext{
    color: rgb(196, 191, 191);
    font-weight: bold;
}
.newtext2{
    color: rgb(226, 223, 223);
    font-weight: bold;
    margin-top: -2%;
}
.mytext{
    margin-bottom: -3%;
    color: rgb(226, 223, 223);

}
.mycall{
    height: 80%;
    width: 90%;
    padding: 5px;
    margin-right:22px;
    background-color: rgb(46, 46, 45);
    font-weight: bold;
}
.neha{
    padding: 8%;
}
@media(min-width:300px) and (max-width:1000px){
    .naaz{
        display: none;
    
    }
    .neha .div2{
        text-align: center;
    }
   .maindiv{
    clip-path: none;
   }
}