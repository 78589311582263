.world{
    background-image: url('../images/About/Real-world.png');
    background-size: contain;
    height: 100%;
}
.section-1 {
    width: 50%;
}
.paragrap-holder{
    width: 100%;
}
.sectionx{
    width: 50%;
}

.inp-box{
    width: 100%;
    padding: 3% 0%;
    border: 0.5px solid #b5b3b3 !important;
}



.button {
    display: flex;
  }
  
  .box {
    width: 40px !important;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    transition: all .8s;
    cursor: pointer;
    position: relative;
    background: #ff9900;
    overflow: hidden;
  }
  
  .box:before {
    content: "M";
    position: absolute;
    top: 0;
    background: #0f0f0f;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(100%);
    transition: transform .4s;
  }
  
  .box:nth-child(2)::before {
    transform: translateY(-100%);
    content: 'E';
  }
  
  .box:nth-child(3)::before {
    content: 'S';
  }
  
  .box:nth-child(4)::before {
    transform: translateY(-100%);
    content: 'S';
  }
  
  .box:nth-child(5)::before {
    content: 'A';
  }
  .box:nth-child(6)::before {
    transform: translateY(-100%);
    content: 'G';
  }
  .box:nth-child(7)::before {
    content: 'E';
  }
  
  .button:hover .box:before {
    transform: translateY(0);
  }
.Icon{
    font-size: 20px;
}

::placeholder{
    text-align: start;
    padding-left: 5% !important;
    color: #b5b3b3;
    font-weight: 100 !important;
    letter-spacing: 1px;
}
textarea::placeholder{
    padding-left: 3% !important;
}
.headings{
    color: black !important;
    letter-spacing: 1px;
}

.inp-box:focus{
    outline: 0.5px solid #ff9900 !important;
}

.icon2{
    padding-right: 45% !important;
    color: #ff9900;
}
.icon2:hover{
    padding-right: 25% !important;
    color: #010101;
}
.textarea{
  height: 100% !important;
}

@media (max-width: 576px) {
    .flex-column-sm {
      flex-direction: column !important;
      align-items: center;
      width: 100% !important;
    }
    .world{
        padding: 0% 0%!important;
       
        margin: 0% 0% !important;
    }
    .section-1{
        margin-top: 10% !important;
        width: 100%;
    }
    .sectionx{
      width: 100%;
    }
    .paragrap-holder{
        margin: 0% !important;
        padding: 0% !important;
    }
    .headings{
        padding: 0% !important;
        margin: 0% !important;
        display: block;
        width: 100%;
    }
    .para{
        padding: 0% !important;
        margin: 0% !important;
    }
    .sectionx{
        margin: -10% 0% 0% 0% !important;
    }
    .zxxx div{
        margin-bottom: 5%;
    }
    .box{
        width: 18px !important;
    }
  }

  .para-text{
    font-size: 1.5em;
  }