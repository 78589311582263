:root {
    --white: white;
}

.active {
    font-size: 20px !important;
    text-decoration: none !important;
    color: rgb(255, 106, 0) !important;
}

.Nav-optns li a {
    text-decoration: none !important;
}

.Load {
    display: flex;
    width: 100%;
    position: fixed ;
    top: 0;
    left: 0;
    right: 0;
    justify-content: space-between;
    padding: 0 2%;
    align-items: center;
    height: 3em;
    background-color: #ffb914;
    z-index: 9999;
    margin: 0;
}

.Nav-Section {
    display: flex;
    align-items: center;
}

.Nav-optns {
    display: flex !important;
    justify-content: start;
    align-content: center;
    margin: 0em 0em 0em 10em;
}

.option {
    margin: 0em 1em;
    list-style-type: none;
    color: var(--white);
    letter-spacing: 1px;
    position: relative;
    padding: 3px;
    text-shadow: 2px 3px 4px #00000021;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
}

.list-items1 {
    list-style-type: none;
}

.option::after {
    content: "";
    position: absolute;
    width: 0%;
    left: 0%;
    height: 100%;
    border-bottom: 3px solid var(--white);
    transition: ease-in-out 0.4s;
    cursor: pointer;
}

.option:hover.option::after {
    width: 100%;
}

.social button {
    border: none;
    background-color: black;
    color: #ffb914;
    padding: 0.3em 1.2em;
    border-radius: 15px;
    transition: all 0.2s;
    margin: 0em 0.2em;
}
@media only screen and (max-width: 1024px) {
    .social {
      display: none;
    }
  }

.social button:hover {
    color: var(--white);
}

.logo-social {
    font-size: 1.7em;
    margin: 0em 0.3em;

}

.l1 {
    color: black;
    font-weight: bold;
}

.l1:hover {
    color: rgba(0, 0, 0, 0.562);
    cursor: pointer;
}

.l2 {
    color: blue;
    font-weight: bold;
}

.l2:hover {
    color: rgba(0, 0, 255, 0.852);
    cursor: pointer;

}

.l3 {
    color: rgb(253, 4, 46);
}

.l3:hover {
    color: rgba(253, 4, 46, 0.555);
    cursor: pointer;
}

@media(max-width:788px) {
    .Load {
        display: none;
    }
}

@media(max-width:1024px) {
    
}