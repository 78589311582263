.Contact-Main-Row {
    /* z-index: -2; */
    position: relative;
    background-image: url("../images/ImageAbout/32325.jpeg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed; 
    min-height: 60vh;
    
}

.Contact-Heading {
    margin-top: 120px;
    font-weight: bold;
    letter-spacing: 4px;
    font-size: 50px;
    color: #ffffff;
    text-shadow:1px 2px 10px rgba(0, 0, 0, 0.902);
    
  }

 