:root {
  --mustard:#ff9900;
  --white: rgb(186, 76, 76);
}
body{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background-color: var(--white) !important;
}
.small-heading h4 span {
  background-color: var(--mustard);
  transform: skew(-20deg, 0deg);
  display: inline-flex;
  width: 4px;
  height: 15px;
  position: relative;
  margin-left: 20px;
  margin-right: 10px;
}

.small-heading h4 span:after,
.small-heading h4 span:before {
  background-color: var(--mustard);
  content: "";
  width: 4px;
  height: 100%;
  position: absolute;
  right: 8px;
  top: 0;
  opacity: 0.8;
}

.small-heading h4 span:after {
  right: 16px;
  opacity: 0.5;
}

.small-heading h4 {
  font-size: 16px;
  color: var(--mustard);
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
}
.app-feature {
  margin-top: 20px !important;
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.app-feature li:not(:last-of-type) {
  margin-right: 20px;
}

.app-feature li {
  display: inline-block;
  align-items: center;
}

.app-feature li .feature-icon {
  width: 40px;
  margin-bottom: 10px;
}

.feature-icon {
  font-size: 60px;
  color: var(--white);
}

.app-feature li h3 {
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--mustard);
}

.download-btn {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
  flex-direction: row;
  flex-wrap: nowrap;
}

.links {
  width: 170px;
}

.links img {
  outline: none;
  border: none;
  min-width: 100%;
}

.links img:hover {
  margin-top: -10%;
  transition: all 0.5s;
}



.taxi-holde1 {
    background-image: url('../images/Booking/taxi-background.png');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    margin-left: -10%;
    width: 100%;
    min-height: 30rem;
    z-index: -2 !important;
    position: relative;
  }

  

  .taxi1{
    background-image: url('../images/Booking/taxi.png');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    position: absolute;
    width: 70%;
    height:100%;
    top: 15%;
    right: 10%;
  }
  .mockup1 {
    background-image: url("../images/Booking/mockup.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    position: absolute;
    width: 40%;
    height:100%;
    top: -24%;
    right: 35%;
    z-index: -1;
  }
  .character1{
    background-image: url("../images/Booking/charecter.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    position: absolute;
    width: 20%;
    height:70%;
    top: 30%;
    right: 15%;
  }
  
  .location1 {
    background-image: url("../images/Booking/location-marker.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    position: absolute;
    width: 1250%;
    height:20%;
    top: 0%;
    right: 29%;
    animation: bounce 2s infinite ;
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  
  40% {
      transform: translateY(-20px);
  }
  60% {
      transform: translateY(-10px);
  }
  }
  @media (max-width:500px){
    .taxi-holde1{
        width: 100%;
        background-size: contain;
    }
    .imgs-copy{
      margin-top: -25%;
    }
    .taxi1{
      top: 6%;
    }
    .character1{
      top: 20%;
    }
    .mockup1{
      top: -10%;
    }
    .location1{
      top: 20%;
      right: 25%;
      width: 50px !important;
    }
  }
  @media (max-width:1100px){
    .taxi-holde1{
        width: 100%;
        background-size: contain;
    }
    
    .taxi1{
      top: 6%;
    }
    .character1{
      top: 20%;
    }
    .mockup1{
      top: -10%;
    }
    .location1{
      top: 20%;
      right: 25%;
      width: 50px !important;
    }
  }
  