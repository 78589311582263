:root{
  --white: #ffffff;
}


.Main-Section{
  overflow: hidden;
}
.home-section{
    background-image: url('../images/Section/slider-bg.jpg');
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    height: 120%;
    width: 100%;
    padding: 5% 2%;
    position: relative; 
  z-index: 1; 
}
.section-1{
  width: 50%;
}
.section-2{
  width: 50%;
  position: relative;
  
}
.small-heading h4{
    color: var(--white);
    letter-spacing: 0.5px;
}

.big-heading h1{
   color: var(--white);
   font-size: 325%;
   letter-spacing: 0.5px;
}

.section-para p{
    color: var(--white);
    font-size: 115%;
    letter-spacing: 0.5px;
}
.carss{
 position: relative;
 z-index: 2 !important;
 
}
.shape{
  clip-path: polygon(100% 0, 40% 100%, 0 100%, 62% 0);
  transform: skew(-27deg, 0deg) !important;
  position: absolute;
  width: 55%;
  height: 145%;
  background-color: #ffb914;
  top: -21% !important; 
  left: 11.5% !important;
  z-index: -1;
}

.shape2{
  content: "";
  clip-path: polygon(55% 0, 100% 0%, 45% 100%, 0% 100%);
  transform: skew(23deg, 0deg);
  position: absolute;
  width: 30%;
  height: 30%;
  background-color: rgb(32, 34, 34);
  top: 5% !important;
  left: 22.9% !important;
  z-index: -1;
}
.shape3{
  content: "";
  clip-path: polygon(55% 0, 100% 0%, 45% 100%, 0% 100%);
  transform: skew(-31deg, 0deg) !important;
  position: absolute;
  width: 40%;
  height: 125%;
  background-color: rgb(255, 255, 255);
  top: -22%;
  right: 15%;
  z-index: -1;
}


@media (max-width: 998px) {
  .carss {
    top: -20%;
  }
}

@media (max-width:1920px){
  .section-1{
    margin-left: 10%;
  }
}

@media (max-width:998px){
  .section-2{
 display: none;
  }
  .section-1{
    display: block;
    width: 100%;
    text-align: center;
  }
  
}

@media (max-width:500px) {
  
  .small-heading 
  {
    margin-top:20%;
  }
}

@media (max-width:800px) {
  .small-heading 
  {
    margin-top: 10%;
  }
}

.bnsection {
    margin: 0;
    height: auto;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
  }
  
  .bnsection {
    --border-right: 6px;
    --text-stroke-color: rgb(219, 218, 218);
    --animation-color: #ffb415;
    --fs-size: 2em;
    letter-spacing: 3px;
    text-decoration: none;
    font-size: var(--fs-size);
    font-family: "Arial";
    position: relative;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px var(--text-stroke-color);
  }
  .hover-text {
    position: absolute;
    box-sizing: border-box;
    content: attr(data-text);
    color: var(--animation-color);
    width: 0%;
    inset: 0;
    border-right: var(--border-right) solid var(--animation-color);
    overflow: hidden;
    transition: 0.5s;
    -webkit-text-stroke: 1px var(--animation-color);
  }
  .bnsection:hover .hover-text {
    width: 100%;
    filter: drop-shadow(0 0 23px var(--animation-color))
  }