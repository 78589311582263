.car1 {
    width: 100%;
}

.eye {
    width: 15px;
    margin-left: 63%;
    margin-top: -70px;
}

.Number {
    margin-left: 71%;
    margin-top: -60px;
}

.information {
    font-weight: bold;
    font-size: 22px;
}

.comments {
    width: 13px;
    margin-left: 84%;
    margin-top: -80px;
}

.Number2 {
    margin-left: 91%;
    margin-top: -64px;
}

.hr {
    border-width: 3px;
    height: 5px;
    border-color: #c3c3d185;
    width: 90%;
}

.Detail {
    margin: 10px;
    margin-top: -15px;
}

.latest {
    /* font-size: 25px; */
    font-weight: bold;
    color: #FFb914;
    text-shadow: 0px 2px 3px #0000001b;
    
}

.Blog {
    font-weight: bold;
}

@media (max-width: 500px) {
    .latest {
        font-size: 20px;
    }

    .Blog {
        font-size: 40px;
    }
}