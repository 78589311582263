:root {
    --bs-orange: #f77001;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
}

.backcarimage {
    background: url("../images/ImageAbout/carbackimg.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-origin: initial;
    background-size: cover;
    position: relative;
    z-index: 1;
}

.backcarimage::after {
    background-color: rgba(0, 0, 0, 0.8);
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;

    z-index: -1;
}

.left-box {
    position: relative;
    z-index: 1;
}

.left-box::before {
    background: #fff none repeat scroll 0 0;
    right: 43%;
    width: 20%;
    height: 100%;

    content: "";
    position: absolute;
    top: 0;
    transform: skew(45deg);
    z-index: -1;
}

.left-box::after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    transform: skewX(45deg);
    z-index: -1;

    background: var(--bs-orange);
    right: 55%;
    width: 120%;
}

.right-box h3 {
    color: var(--bs-white);
    font-size: 34px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 50px;
    ;
    margin-bottom: 15px;
    text-transform: capitalize;
}

a {
    cursor: pointer;
}

.abutton {
    text-decoration: none;
    text-transform: uppercase;
    background: transparent;
    border: 2px solid var(--bs-orange);
    color: var(--bs-gray);
    padding: 8px 15px;
    margin: 1rem;
    letter-spacing: 1px;
    font-weight: 500;
    position: relative;
    display: inline-block;
}

.abutton::before,
.abutton::after {
    border-color: var(--bs-orange);
    border-style: solid;
    box-sizing: border-box;
    width: 1rem;
    height: 1rem;
    content: "";
    position: absolute;
    display: block;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.abutton::after {
    border-width: 2px 0 0 2px;
    left: -6px;
    top: -6px;
}

.abutton::before {
    border-width: 0 2px 2px 0;
    right: -6px;
    bottom: -6px;
    z-index: 5;
}

.abutton:hover {
    background-color: #f77001;
    border-color: #f77001;
    color: #fff;
    transition-duration: 10px;
    text-decoration: none;
}

.abutton:hover.abutton::after,
.abutton:hover.abutton::before {
    width: 106%;
    height: 123%;
    transition: linear 0.4s;
}
.animated-buttons-my {
    font-family: inherit;
    font-size: 20px;
    background: rgb(255, 255, 255);
    color: white;
    padding: 0.3em 1em;
    padding-left: 0.9em;
    margin-bottom: 1em;
    margin-left: 1em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
}

.btns-logs-my {
    width: 2em;
}

.animated-buttons-my span {
    color: #ffb415;
}

.animated-buttons-my span {
    display: block;
    margin-left: 0.5em;
    transition: all 0.3s ease-in-out;
}

.animated-buttons-my img {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
}

.animated-buttons-my:hover .svg-wrapper-my {
    animation: fly-1 0.6s ease-in-out infinite alternate;
}

.animated-buttons-my:hover img {
    transform: translateX(2.8em) scale(1.1);
}

.animated-buttons-my:hover span {
    transform: translateX(6.2em);
}

@media screen and (min-width:768px) {
    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .left-box {
        padding-bottom: 10px;
        padding-top: 10px;
    }
}

@media screen and (max-width:500px) {
    .right-box {
        /* display: none; */
        text-align: center;
    }
}

@media screen and(max-width:425px) {
    .animated-buttons-my {
        margin-left: -10em;
        background-color: black;
    }
    .right-box h3 { font-size: 30px;}
    
}
@media (max-width:320px) {
    .right-box h3 { font-size: 25px;
        color: var(--bs-white);
       
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 50px;
        ;
        margin-bottom: 15px;
        text-transform: capitalize;
    }
}