.card-info {
  font-size: 28px;
  font-weight: bold;
}

.card-head {
  font-size: 30px;
  font-weight: bold;
  color: #ff9900;
}

.card-head-two {
  font-size: 45px;
  font-weight: bold;
}

.head-row {
  border-width: 3px;
  height: 5px;
  border-color: #c3c3d1ab;
  width: 100%;
}


.Card-Button {
  padding: 0.8em 1.1em;
  position: relative;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: grey;
  letter-spacing: 2px;
}

.Card-Button::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(20deg);
  background-image: linear-gradient(
    to right bottom,
    #ff9900,
    #ff9f04,
    #ffa509,
    #ffaa0f,
    #ffb015,
    #ffb216,
    #ffb316,
    #ffb517,
    #ffb312,
    #ffb00d,
    #ffae07,
    #ffab01
  );
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.Card-Button:hover::before {
  width: 105%;
}

.Card-Button:hover {
  color: black;
}
.driver-image {
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.347);
  background-color: rgb(231, 231, 231);
}
