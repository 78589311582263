.navbar-nav .nav-item {
  padding: 0px 20px;
}

.navbar-toggler {
  display: block;
  outline: none;
  border: none;
}

.navbar-toggler-icon {
  color: var(--primary-bg-color);
}

.navbar-nav .nav-item .nav-link {
  color: white;
  font-weight: 600;
  font-size: 16px;

}

.active-link {
  color: var(--primary-bg-color) !important;
}

.navbar-collapse {
  position: fixed;
  top: 0;

  left: -100%;
  height: 100%;
  width: 100%;

  z-index: 1000;
  background-color: white;
}

.navbar-collapse.active {
  left: 0;

}


.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #555;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.smallScreennav li .nav-link {
  font-size: 15px;
  font-weight: 400;
  border-bottom: solid 0.3px #ddd;
}

.bigScreenNavlink nav .nav-link {
  color: white;
  font-weight: 500;

}

.connectElement {
  margin-left: 200px;
}



@media screen and (max-width=720px) {
  .bigScreenNavlink {
    display: none !important;
  }
}

input[type="search"] {
  position: relative;
  padding: 0px 20px;
  color: var(--primary-bg-color);
}

input[type="search"]::after {
  content: "\f002";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: var(--primary-bg-color);
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.navPrimeBtn {
  border: none;
  outline: none;
  background: var(--primary-bg-color);
  color: white;
  font-family: 400;
  margin-left: 10px;
}



.navPrimeBtn:hover {
  background-color: #000;
  color: white;
}


@media only screen and (max-width: 767px) {
  .navbar-area {
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding: 0;
  }

  .navbar-area nav .navbar-brand {
    font-size: 25px;
  }

  .navbar-area nav .navbar-brand img {
    max-width: 130px;
  }

  .navbar-area nav .navbar-nav {
    overflow-y: scroll;
    max-height: 70vh;
    padding-right: 15px;
  }

  .navbar-area nav .navbar-nav::-webkit-scrollbar {
    width: 10px;
  }

  .navbar-area nav .navbar-nav::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .navbar-area nav .navbar-nav::-webkit-scrollbar-thumb {
    background: #888;
  }

  .navbar-area nav .navbar-nav::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .navbar-area nav .navbar-nav .nav-item {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .navbar-area nav .navbar-nav .nav-item a i {
    display: none;
  }

  .navbar-area nav .navbar-nav .nav-item .dropdown-menu {
    position: relative;
    top: 0 !important;
    width: 100%;
    opacity: 1;
    visibility: visible;
    padding: 10px 0;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .navbar-area nav .navbar-nav .nav-item .dropdown-menu.mega-dropdown-menu .nav-item {
    width: 100%;
    float: initial;
    padding: 0px 15px;
  }

  .navbar-area nav .navbar-nav .nav-item .dropdown-menu.mega-dropdown-menu .nav-item a:last-child {
    border-bottom: 1px solid #e5e5e5 !important;
  }

  .navbar-area nav .navbar-nav .nav-item .dropdown-menu li {
    padding: 5px 15px;
  }

  .navbar-area nav .others-options {
    margin: 10px 0 15px 10px;
  }

  .navbar-area.is-sticky {
    padding: 0;
  }

  .navbar-area.navbar-style-2 nav .navbar-brand {
    color: var(--light-text-color);
  }
}