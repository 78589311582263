
.navbar-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: auto; 
}

.LogoYoo {
  width: 150px;
  top: -10px;
}


.navbar-area.navbar-style-2 nav .navbar-brand {
  color: var(--light-text-color);
}
.navbar-area.navbar-style-2 nav .navbar-brand .black-logo {
  display: none;
}
.navbar-area.navbar-style-2 nav .navbar-nav .nav-item {
  padding-left: 15px;
  padding-right: 15px;
}
.navbar-area.navbar-style-2 nav .navbar-nav .nav-item a {
  color: #2a2a72;
}
.navbar-area.navbar-style-2.is-sticky .navbar-brand {
  color: #18181b;
}
.navbar-area.navbar-style-2.is-sticky .navbar-brand .black-logo {
  display: block;
}
.navbar-area.navbar-style-2.is-sticky .navbar-brand .white-logo {
  display: none;
}
.navbar-area.navbar-style-2.is-sticky .navbar-nav > .nav-item > a {
  color: #18181b;
}
.navbar-area.navbar-style-3 .container {
  max-width: 1365px;
}
.navbar-area.bg-white {
  padding: 10px 0;
}
.navbar-area .container-fluid {
  max-width: 1920px;
  padding-left: 30px;
  padding-right: 30px;
}
.navbar-area.dark-navbar {
  background: #000;
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar-area.dark-navbar .navbar-nav .nav-item a {
  color: #fff;
}
.navbar-area.dark-navbar .navbar-nav .nav-item a:hover, .navbar-area.dark-navbar .navbar-nav .nav-item a:focus, .navbar-area.dark-navbar .navbar-nav .nav-item a.active {
  color: #fff;
}
.navbar-area.dark-navbar.is-sticky {
  padding-bottom: 2px;
  padding-top: 2px;
  background-color: #000 !important;
}

.navbar-toggler {
  border: none;


  background: transparent !important;

}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler .icon-bar {
  width: 28px;
  transition: all 0.3s;
  background: var(--secondary-bg-color);
  height: 2px;
  display: block;
}

.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
  left: 4px;
  position: relative;
}
.navbar-toggler .middle-bar {
  opacity: 0;
  margin: 6px 0;
}
.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  left: 4px;
  position: relative;
}
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
  left: 0;
}
.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
  left: 0;
}

@media screen and (max-width:600px){
  .navbar-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: auto;
  }
}

.Login-btn{
  background-color: #E89D1E;
}
.join-us-btn{
  background-color: #293747;
}



@media only screen and (min-width: 768px) {
  .navbar_white{
    display: none !important;
  }
}