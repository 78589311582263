:root {
    --bs-white: rgb(255, 255, 255);
    --bs-black: black;
    --bs-gray: rgb(66, 66, 66);
}

.backimage {
    background: url("../images/ImageAbout/img5e.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    
    
}

.containimg {
    margin-top: 70px;
}

.head {
    color: var(--bs-black);
}

.headimg {
    background: transparent;
}

.headparagraph {
    color: var(--bs-black) !important;
    font-weight: 500;
}

@media screen and (max-width:600px) {
    .containimg {
       display: none;
    }
}