body {
    background-color: #eeeeee;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 17px;
    line-height: 27px;
    color: #666;
    font-weight: 400;
    letter-spacing: -0.2px;
    position: relative;
    overflow-x: hidden;
}

.main_div {
    background-image: url("../images/home/map.png");
    background-repeat: no-repeat;
   
    left: -10px;
    top: 40px;
}

.div_1 {
    margin-top: 5vh
}

.heading_section h4,
.heading_section2 h4 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-flex;
    align-items: center;
}

.heading_section h4 {
    color: var(--orange);
}

.heading_section2 h4 {
    font-weight: 500;
}

.heading_section h4 span,
.heading_section2 h4 span {
    transform: skew(-20deg, 0deg) !important;
    display: inline-flex;
    width: 4px;
    height: 15px;
    position: relative;
    margin-left: 20px;
    margin-right: 10px;
}

.heading_section h4 span {
    background-color: var(--orange) !important;
}

.heading_section2 h4 span {
    background-color: var(--white) !important;
}

.heading_section h4 span:after,
.heading_section h4 span:before {
    background-color: var(--orange);
    content: "";
    width: 4px;
    height: 100%;
    position: absolute;
    right: 8px;
    top: 0;
    opacity: 0.8;
}

.heading_section h4 span:after {
    right: 16px;
    opacity: 0.5;
}

.heading_section h2 {
    font-weight: 700;
    letter-spacing: -1.5px;
    color: #222;
    margin: 0 0 10px;
}

.heading_section p {
    margin-bottom: 0;
    margin-top: 0;
    color: var(--secondary);
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.slider_div {
    padding: 0;
    position: relative;
    margin-left: -5px;
    background-color: white;
    position: relative;
}

.slider_div::before {
    content: "''";
    position: absolute;

    background-color: #ff9900;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    font-size: 4rem;
    color: #222;
    width: 60px;
    padding-top: 30px;
    height: 50px;
    position: absolute;
    right: 100px;
    top: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider_div1 {
    overflow: hidden;
    padding: 25px 5px 5px;
    touch-action: pan-y;

}

.image_round {
    border-radius: 50%;
}

.text_spac {
    margin-left: 20px;
}

.text_spac_h3 {
    font-weight: 600;
    color: #222;
    letter-spacing: -0.5px;
    font-family: 'Space Grotesk', sans-serif;
    -webkit-font-smoothing: antialiased;
    color: #222;
    font-size: 20px;
}

.text_spac_span {
    display: block;
    margin-top: 10px;
    font-size: 15px;
}



.b {
    background-color: var(--orange);
    background-image: repeating-linear-gradient(45deg, #f7a20f 0, #f1a218 2px, transparent 0, transparent 50%);
    background-size: 10px 10px;
    height: auto;
    padding: 50px 40px;
}

.textwhite {
    color: var(--white)
}

.heading_section2 h4 span:after,
.heading_section2 h4 span:before {
    background-color: var(--white);
    content: "";
    width: 4px;
    height: 100%;
    position: absolute;
    right: 8px;
    top: 0;
    opacity: 0.8;
}

.heading_section2 h4 span:after {
    right: 16px;
    opacity: 0.5;
}

.heading_section2 h2 {
    font-weight: 700;
    letter-spacing: -1.5px;
    margin: 0 0 10px;
}


.li_class {
    visibility: visible;
    animation-delay: 300ms;
    animation-name: fade-in-right;
}

.image {
    width: 50px;
    height: 50px;
}

.image-content {
    margin-left: 20px;
}

.uldiv {
    margin-left: -30px;
}

@media screen and (max-width: 400px) {
    .b {
        margin-top: 10vh !important;
    }
}

@media screen and (max-width: 800px) {
    .b {
        margin-top: 10vh !important;
    }
}

:root {
    --blue: #0d6efd;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #d63384;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #198754;
    --teal: #20c997;
    --cyan: #0dcaf0;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #0d6efd;
    --secondary: #6c757d;
    --success: #198754;
    --info: #0dcaf0;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #212529;
}

*,
::after,
::before {
    box-sizing: border-box;
}

*,
::after,
::before {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    background-color: #222;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #ff9900;
}

::selection {
    background-color: #ff9900;
    color: #fff;
}